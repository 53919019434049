import React, { useEffect, useState, Component } from "react";
import { Tabs, Tab, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
function Details() {
  const { user, setTransactionLoaderState } = useAuth();
  const [passwordError, setPasswordError] = useState("");
  const [data, setData] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    businessname: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
  });

  const [formError, setFormError] = useState({
    businessname: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const clearForm = () => {
    setFormData({
      businessname: "",
      email: "",
      password: "",
      confirmPassword: "",
      mobile: "",
    });
    setPasswordError("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password != formData.confirmPassword) {
      setFormError({ ...formError, password: ["Password does not match"] });
      return;
    }
    // clearForm();
    createMerchantApi();
  };

  const createMerchantApi1 = async () => {
    console.log("hiii");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        txnId: formData.txnid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      setTransactionLoaderState(true);
      const res = await fetch(
        "https://cubexis.in/api/getTransactionDetails.php",
        requestOptions
      );

      const data = await res.json();

      console.log(data);

      if (data.status === "00") {
        setTransactions(data.details);
      } else {
        if (data.errors) {
          setFormError(data.errors);
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error("Error fetching Form data:", error);
    }
  };

  const createMerchantApi = async () => {
    console.log("hiii");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic cGd1c2VybmV3OlRlc3RAMTIzNDU=");

      var raw = JSON.stringify({
        merchantId: user.merchantId,
        referenceId: formData.txnid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      setTransactionLoaderState(true);
      const res = await fetch(
        "https://reports.remittpe.com/api/Intent/fetchIntentStatus",
        requestOptions
      );

      const data = await res.json();

      console.log(data);
      if (data.data) {
        setTransactions({ ...data.data, client: data.data.vpa.toString().split("|")[1], vpa: data.data.vpa.toString().split("|")[0], payment_id: data.data.vpa.toString().split("|")[2] });
        setFormError({});
      } else {
        setTransactions({});
        setFormError({ ...formError, txnid: "No Transaction found" });
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error("Error fetching Form data:", error);
    }
  };

  console.log("transactions", transactions);
  

  return (
    <div
    //   className=" p-0 p-lg-5"
      style={{
        // backgroundColor: "#F1EFEF",
        fontFamily: "poppins",
        height: "100%",
      }}
    >
      <div
        // className="full-width-container text-dark bg -light  "
        // style={{
        //   border: "0px solid #000000",
        //   borderTop: "0px solid #000000",
        //   borderRadius: "15px 15px 15px 15px",
        // }}
      >
        <div className="row p-0  p-lg-5">
          <div className="col-3"></div>
          <div
            // className="col-12 p-3 p-lg-4 col-lg-8 bg-white shadow m-0"
            // style={{ borderRadius: "10px", width: '100%' }}
          >
            <form className="row">
              <div className="mb-3 col-12 d-flex align-items-center justify-content-center text-center">
                <div
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    fontFamily: "poppins",
                  }}
                >
                  Transaction Details
                </div>
              </div>

              <div className="col-12">
                <label className="form-label">
                  RefId or RRN{" "}
                  <span style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.txnid}
                  placeholder="Enter RefId or RRN"
                  onChange={(e) =>
                    setFormData({ ...formData, txnid: e.target.value })
                  }
                  required
                />
                {formError.txnid && (
                  <p style={{ color: "red" }} className="error-message">
                    {formError.txnid}
                  </p>
                )}
              </div>

              <div className="row mt-4">
                <div className="col">
                  <button
                    className="btn btn-block text-white bg-success m-2"
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    Fetch Transaction Details
                  </button>
                </div>
              </div>
            </form>
          </div>

          {Object.keys(transactions).length > 0 && (
            <div className="container mt-5">
              {/* <h2 className="mb-4">Transaction Details</h2> */}
              <JSONTable data={transactions} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

class JSONTable extends Component {
  renderTable() {
    const { data } = this.props;

    console.log("data123", data);
    return (
      // <div className="scroll-inner admin-fix-width">
      //     <table className="my-table transparent-table text-center border-bottom " >
      //         <tbody>
      //             {Object.entries(data).map(([key, value], index) => (
      //                 <tr className="my-table transparent-table text-center border-bottom " key={index}>
      //                     <td>{key}</td>
      //                     <td>{typeof value === 'object' ?
      //                         Object.entries(data).map(([key, value], index) => (
      //                             <tr className="my-table transparent-table text-center border-bottom "
      //                                 style={{ fontWeight: "2px" }}
      //                                 key={index}>
      //                                 <td>{key}</td>
      //                                 <td>{JSON.stringify(value)}</td>
      //                             </tr>
      //                         ))
      //                         : value
      //                     }</td>
      //                 </tr>
      //             ))}
      //         </tbody>
      //     </table>
      // </div>
      <div
        className="card  shadow"
        style={{
          width: '100%',
          marginTop: '10px',
          borderRadius: '10px',
          backgroundColor: '#fff',
          border: "none"
        }}
      >
        <h2
          className="mt-2 mb-2 text-center"
          style={{ fontSize: '24px', fontWeight: '700', color: '#1A1A1A' }}
        >
          Transaction Details  </h2>
        <table
          className="table table-bordered mb-4"
          style={{
            borderRadius: '8px',
            overflow: 'hidden',
            backgroundColor: '#ffffff',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          <thead>
            <tr
              style={{
                backgroundColor: '#f7f7f7',
                borderBottom: '2px solid #e0e0e0',
              }}
            >
              <th
                style={{
                  padding: '12px',
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#333',
                  textAlign: 'center',
                }}
              >
                Field
              </th>
              <th
                style={{
                  padding: '12px',
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#333',
                  textAlign: 'center',
                }}
              >
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([key, value], index) => (
              value &&
              <tr key={index}>
                <th style={{
                  padding: '12px',
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#333',
                  textAlign: 'center',
                  border:"none"
                }}>{key}</th>
                <td style={{
                  padding: '12px',
                  fontSize: '14px',
                  color: '#555',
                  textAlign: 'center',
                }}>
                  {typeof value === 'object' ? JSON.stringify(value) : value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    );
  }

  render() {
    return (
      <div>
        {this.renderTable()}
      </div>
    );
  }
}


export default Details;
