import React, { useEffect, useState } from "react";
import { Badge, Button, Modal, Form, FormControl } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
const arr = [{ label: "Name", value: "Name" }];

const ViewAllWebhooks = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [webhookUrlError, setWebhookUrlError] = useState("");
  const [selectedEventError, setSelectedEventError] = useState("");
  const [loading, setLoading] = useState(true);
  const ViewAllMerchant = async () => {
    console.log(ViewAllMerchant, "shee");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user.merchantId,
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://cubexis.in/api/getAllMerchantWebhooks.php`,
        requestOptions
      );
      const data = await res.json();
      console.log("Merchant", data);
      if (data.status === "00") {
        setData(data.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };
  const ViewAllMerchantEvents = async () => {
    console.log(ViewAllMerchant, "shee");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user.merchantId,
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://cubexis.in/api/getAllMerchantWebhookEvents.php`,
        requestOptions
      );
      const eventsData = await res.json();
      console.log("dhdh", eventsData);
      if (eventsData.status === "00") {
        setEvents(eventsData.data);
      } else {
        console.error("Error fetching events:", eventsData.message);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleAddWebhookClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const isValidForm = () => {
    setWebhookUrlError("");
    setSelectedEventError("");

    let isValid = true;

    if (!webhookUrl.trim()) {
      setWebhookUrlError("Webhook URL is required.");
      isValid = false;
    }
    if (selectedEvent.trim() == "") {
      setWebhookUrlError("Webhook Event is required.");
      isValid = false;
    }

    return isValid;
  };
  const addWebhook = async () => {
    try {
      if (!isValidForm()) {
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        merchantId: user.merchantId,
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
        webhook_url: webhookUrl,
        event: selectedEvent,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://cubexis.in/api/addMerchantWebhookUrl.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        Swal.fire({
          icon: "success",
          title: "Add Successfully",
          text: "Webhook added successfully!",
        });
        ViewAllMerchant();
        handleCloseModal();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };
  useEffect(() => {
    ViewAllMerchant();
    ViewAllMerchantEvents();
  }, []);
  useEffect(() => {}, [data, events]);

  console.log(selectedEvent);
  return (
    <>
<div className="card shadow m-3">
  <div className="p-2">
    <h4>View All Webhooks</h4>
  </div>

  <div>
    <table className="table" style={{ fontFamily: "Poppins, sans-serif" }}>
      <thead>
        <tr>
          <th
            className="text-white text-center py-3 "
            style={{ width: "20%" ,background: "#2c3e50" }} 
          >
            Webhook URL
          </th>
          <th className=" text-white text-center py-3" style={{ width: "15%" ,background: "#2c3e50"}}>
            User Id
          </th>
          <th className=" text-white text-center py-3" style={{ width: "10%",background: "#2c3e50" }}>
            Is Active
          </th>
          <th className=" text-white text-center py-3" style={{ width: "15%" ,background: "#2c3e50"}}>
            Created On
          </th>
          <th className=" text-white text-center py-3" style={{ width: "15%",background: "#2c3e50" }}>
            Updated On
          </th>
          <th
            className=" text-white text-center py-3"
            style={{ width: "25%",background: "#2c3e50" }} 
          >
            Webhook Type
          </th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan="6" className="text-center">
              Loading...
            </td>
          </tr>
        ) : (
          data.map((item) => (
            <tr key={item.UserName}>
              <td className="text-center align-middle">
                {item.webhookUrl || "NA"}
              </td>
              <td className="text-center align-middle">{item.userId}</td>
              <td className="text-center align-middle">
                {item.IsActive || "NA"}
              </td>
              <td className="text-center align-middle">
                {new Date(item.CreatedOn).toLocaleDateString() || "NA"}
              </td>
              <td className="text-center align-middle">
                {new Date(item.UpdatedOn).toLocaleDateString() || "NA"}
              </td>
              <td className="text-center align-middle">
                {item.webhook_type || "NA"}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
</div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Webhook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="webhookUrl">
              <Form.Label>Webhook URL</Form.Label>
              <FormControl
                type="text"
                placeholder="Enter webhook URL"
                value={webhookUrl}
                onChange={(e) => setWebhookUrl(e.target.value)}
              />
              <small className="text-danger">{webhookUrlError}</small>
            </Form.Group>
            <Form.Group controlId="event">
              <Form.Label>Event</Form.Label>
              <Form.Control
                as="select"
                value={selectedEvent}
                onChange={(e) => {
                  setSelectedEvent(e.target.value);
                }}
              >
                <option key={"#"} value={""}>
                  {"Select Event"}
                </option>
                {events.map((event) => (
                  <option key={event.pk_event_id} value={event.event_title}>
                    {event.event_title}
                  </option>
                ))}
              </Form.Control>
              {/* <small className="text-danger">{selectedEventError}</small> */}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addWebhook}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewAllWebhooks;